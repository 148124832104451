import React, { Component } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link, animateScroll as scroll } from "react-scroll";
import logo from "../media/logo.png";
import arrow from "../media/arrow.svg";

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      auth: props.auth,
      currentPage: props.currentPage,
      navExpand: false,
      navColor: props.navColor,
      navHeight: 0,
    };

    this.toggleNav = this.toggleNav.bind(this);
  }

  toggleNav(e) {
    e.preventDefault();
    this.setState({ navExpand: !this.state.navExpand });
  }

  componentDidMount() {
    this.setState({
      navHeight: document.getElementById("navbar-id").clientHeight,
    });
  }

  render() {
    return (
      <div
        id="navbar-id"
        className={`navbar-full w-100 transition-all ${
          this.state.currentPage === "home" && !this.state.auth
            ? "white-bg"
            : "white-bg"
        }`}
      >
        <div className="container">
          <div className="row py-3 w-100">
            <div
              className={`col-auto logo-col ${
                this.state.currentPage === "home" ? "" : ""
              }`}
            >
              {this.state.auth ? (
                <RouterLink to="/">
                  <img
                    alt=""
                    className={`transition-all ${
                      this.state.currentPage === "home"
                        ? "white-logo"
                        : "white-logo"
                    }`}
                    src={logo}
                  />
                </RouterLink>
              ) : (
                <Link
                  spy={true}
                  smooth={true}
                  duration={500}
                  className="clickable transition-all w-100"
                  to="home-top"
                >
                  <img
                    alt=""
                    className={`transition-all ${
                      this.state.currentPage === "home"
                        ? "white-logo"
                        : "white-logo"
                    }`}
                    src={logo}
                  />
                </Link>
              )}
            </div>

            <div
              className={`col m-only link-expand-col ${
                this.state.currentPage === "home" && !this.state.auth
                  ? "display-none"
                  : ""
              }`}
            >
              <a
                onClick={this.toggleNav}
                className={` transition-all ${
                  this.state.navExpand ? "flip-switch" : ""
                }`}
              >
                <img alt="" src={arrow} />
              </a>
            </div>

            <div
              className={`col d-only link-col ${
                !this.state.auth && this.state.currentPage === "home"
                  ? ""
                  : "display-none"
              }`}
            >
              <div className="link-row playfair font-black">
                <Link
                  spy={true}
                  smooth={true}
                  offset={0 - this.state.navHeight - 30}
                  duration={500}
                  className="clickable transition-all"
                  to="about"
                >
                  About
                </Link>
              </div>
            </div>

            <div
              className={`col d-only link-col ${
                !this.state.auth && this.state.currentPage === "home"
                  ? "display-none"
                  : ""
              }`}
            >
              <div className="link-row playfair font-black">
                <RouterLink
                  className={
                    this.state.currentPage === "fund" ? "font-orange" : ""
                  }
                  to="/fund"
                >
                  Fund Overview
                </RouterLink>
                <RouterLink
                  className={
                    this.state.currentPage === "portfolio" ? "font-orange" : ""
                  }
                  to="/portfolio"
                >
                  Portfolio
                </RouterLink>
                <RouterLink
                  className={
                    this.state.currentPage === "market" ? "font-orange" : ""
                  }
                  to="/market"
                >
                  Market Information
                </RouterLink>
                <RouterLink
                  className={
                    this.state.currentPage === "team" ? "font-orange" : ""
                  }
                  to="/team"
                >
                  Our Team
                </RouterLink>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`m-only w-100 transition-all playfair d-flex container m-links ${
            this.state.navExpand &&
            this.state.currentPage &&
            !this.state.auth !== "home"
              ? "expanded"
              : ""
          }`}
        >
          <div
            className={`w-100 d-flex ${this.state.auth ? "display-none" : ""}`}
          >
            <Link
              spy={true}
              smooth={true}
              offset={0 - this.state.navHeight - 30}
              duration={500}
              className="clickable transition-all w-100"
              to="about"
            >
              About
            </Link>
          </div>
        </div>

        <div
          className={`m-only w-100 transition-all playfair container m-links ${
            this.state.navExpand && this.state.auth ? "expanded" : ""
          }`}
        >
          <div className="w-100 py-3 d-flex">
            <RouterLink
              className={this.state.currentPage === "fund" ? "font-orange" : ""}
              to="/fund"
            >
              Fund Overview
            </RouterLink>
            <RouterLink
              className={
                this.state.currentPage === "portfolio" ? "font-orange" : ""
              }
              to="/portfolio"
            >
              Portfolio
            </RouterLink>
            <RouterLink
              className={
                this.state.currentPage === "market" ? "font-orange" : ""
              }
              to="/market"
            >
              Market Information
            </RouterLink>
            <RouterLink
              className={this.state.currentPage === "team" ? "font-orange" : ""}
              to="/team"
            >
              Our Team
            </RouterLink>
          </div>
        </div>
      </div>
    );
  }
}

export default Navbar;
