import React, { Component } from "react";

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: props.currentPage,
    };
  }

  render() {
    return (
      <div className="footer-full py-5 w-100">
        <div className="container">
          <div className="row">
            <div className="col-md">
              <h1 className="playfair font-white">Contact us</h1>
            </div>
            <div className="col-md pt-5 pt-md-3">
              <p className="df-display">
                <a
                  href="mailto:investors@nycref.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-white"
                >
                  investors@nycref.com
                </a>
                <br />
                <br />
                <a
                  href="tel:212-742-0733"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-white"
                >
                  212-742-0733
                </a>
                <br />
                <br />
                <a
                  className="font-white"
                  href="https://goo.gl/maps/UfYq5auUNyakDpK99"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  40 Wall Street, 60th Floor, <br className="m-only" />
                  New York, NY 10005
                </a>
              </p>
            </div>
            <a
              className="col-md map-img transition-all"
              href="https://goo.gl/maps/UfYq5auUNyakDpK99"
              target="_blank"
              rel="noopener noreferrer"
            />
          </div>
          <p className="roboto copyright font-300">
            Copyright NYC Real Estate Fund LLC 2020
          </p>
        </div>
      </div>
    );
  }
}

export default Footer;
