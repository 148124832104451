import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Fade } from "react-reveal";

import dots from "../media/dots.svg";

class Market extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: props.auth,
      visible: 1,
    };

    this.mobileScroll = this.mobileScroll.bind(this);
  }

  mobileScroll(e) {
    if (e.target.scrollLeft / e.target.offsetWidth > 0.3) {
      if (e.target.scrollLeft / e.target.offsetWidth > 1.12) {
        if (e.target.scrollLeft / e.target.offsetWidth > 1.8) {
          this.setState({ visible: 4 });
        } else {
          this.setState({ visible: 3 });
        }
      } else {
        this.setState({ visible: 2 });
      }
    } else {
      this.setState({ visible: 1 });
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {}

  render() {
    if (this.state.auth) {
      return (
        <div className="body-market">
          <Navbar auth={this.state.auth} currentPage={"market"} />
          <div className="market-container container pb-5 py-md-5 mb-5">
            <div className="row p-md-5">
              <div className="col-md">
                <Fade top>
                  <h1 className="playfair market-title mb-5 font-black">
                    Market <br />
                    Information
                  </h1>
                </Fade>
              </div>
              <div className="col-md">
                <p className="roboto font-300">
                  New York City has consistently ranked in the top three most
                  liquid real estate markets in the world. Our business model
                  was designed to acquire properties at a price per square foot
                  significantly below the market average. In fact way below the
                  10 year average. By achieving this difficult task{" "}
                  <a className="no-a font-700 font-black">
                    we are not cycle dependant
                  </a>
                  . Our focus is on investment performance rather than asset
                  accumulation.
                </p>
              </div>
            </div>
            <div className="w-100 mt-5 graph-container p-md-5">
              <p className="roboto font-700 mb-3 font-black text-left">
                Manhattan 10 Year <br className="m-only" />
                Historical Pricing
              </p>
              <img
                className="w-100 d-only"
                src={require("../media/marketinfographfinal.png")}
              />
              <img
                className="w-100 m-only"
                src={require("../media/marketinfographfinal.png")}
              />
            </div>
            <Fade left>
              <div className="w-100 py-4 px-3 pl-md-5 pr-md-1 py-md-3 mt-4 graph-stats-container">
                <div className="row">
                  <div className="col-md title">
                    <h3 className="playfair font-black">
                      Price per Buildable SF
                    </h3>
                    <p className="roboto font-400 mt-3">
                      Significantly below yearly averages for last 10 years.
                    </p>
                  </div>
                  <div className="col-md-auto stats-row">
                    <div className="row w-100">
                      <div className="col-md">
                        <div className="col-inner">
                          <p className="roboto font-700">
                            <span className="c-dot green mr-2"></span>1578
                            Lexington
                          </p>
                          <p className="roboto font-300">
                            $169 P/PBS
                            <br />
                            67% below 2020 Avg.
                            <br />
                            48% below 10 year low (2011)
                          </p>
                        </div>
                      </div>
                      <div className="col-md middle">
                        <div className="col-inner">
                          <p className="roboto font-700">
                            <span className="c-dot blue mr-2"></span>245-247
                            East 7th St.
                          </p>
                          <p className="roboto font-300">
                            $180 P/PBS
                            <br />
                            65% below 2020 Avg.
                            <br />
                            44% below 10 year low (2011)
                          </p>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="col-inner">
                          <p className="roboto font-700">
                            <span className="c-dot orange mr-2"></span>154
                            Lexington
                          </p>
                          <p className="roboto font-300">
                            $260 P/PBS
                            <br />
                            29% below 2020 Avg.
                            <br />
                            19% below 10 year low (2011)
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </div>

          <div className="w-100 py-5 sellout-container">
            <div className="container py-5">
              <div className="p-block w-100">
                <Fade left>
                  <p className="roboto font-700">
                    In conjunction with acquiring sites at below market values
                    we have also underwritten the project sellouts at discounted
                    valuations to market comps. We expect this to provide for
                    additional income to investors and act as protection against
                    competing projects.
                  </p>
                </Fade>
                <img className="d-only" src={dots} />
              </div>
              <div className="scroll-display pl-3 pt-3 m-only row w-100">
                <div
                  className={`col ${this.state.visible === 1 ? "white" : ""}`}
                />
                <div
                  className={`col mx-2 ${
                    this.state.visible === 2 ? "white" : ""
                  }`}
                />
                <div
                  className={`col mr-2 ${
                    this.state.visible === 3 ? "white" : ""
                  }`}
                />
                <div
                  className={`col ${this.state.visible === 4 ? "white" : ""}`}
                />
              </div>
              <div
                className="blended-block w-100 px-md-3 my-md-5 py-5"
                onScroll={this.mobileScroll}
              >
                <div className="row w-100">
                  <Fade bottom>
                    <div className="col mr-3">
                      <div className="w-100 py-4 px-3 top-sect first-card">
                        <div className="w-100">
                          <p className="playfair m-0 mb-4">
                            Blended Condo
                            <br />
                            Sellout (PPFT)
                          </p>
                          <p className="df-display m-0">$1,533.00</p>
                          <p className="roboto font-400 m-0 mb-3">
                            NYCREF Sellout (PPFT)
                          </p>
                          <p className="df-display m-0">$2,378.00</p>
                          <p className="roboto font-400 m-0">
                            Market Comps (PPFT)
                          </p>
                        </div>
                      </div>
                      <div className="w-100 px-3 py-3 discount-bar">
                        <p className="roboto w-100 font-400 m-0">
                          Discount to market
                        </p>
                        <p className="df-display w-auto font-green m-0">-35%</p>
                      </div>
                    </div>
                  </Fade>
                  <Fade bottom>
                    <div className="col mx-3">
                      <div className="w-100  top-sect">
                        <div className="w-100 img-sect east7" />
                        <div className="w-100 py-4 px-3 stat-sect d-flex">
                          <div className="w-100">
                            <p className="df-display m-0 mb-4">
                              245-247 East 7th St.
                            </p>
                            <div className="mini-stats w-100 d-flex">
                              <div className="w-50">
                                <p className="df-display m-0">$1,500.00</p>
                                <p className="roboto font-400 m-0">
                                  Underwritten
                                </p>
                              </div>
                              <div className="w-50">
                                <p className="df-display m-0">$2,433.00</p>
                                <p className="roboto font-400 m-0">
                                  Market Comps
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-100 px-3 py-3 discount-bar">
                        <p className="roboto w-100 font-400 m-0">
                          Discount to market
                        </p>
                        <p className="df-display w-auto font-green m-0">-38%</p>
                      </div>
                    </div>
                  </Fade>
                  <Fade bottom>
                    <div className="col mx-3">
                      <div className="w-100  top-sect">
                        <div className="w-100 img-sect lex-1578" />
                        <div className="w-100 py-4 px-3 stat-sect d-flex">
                          <div className="w-100">
                            <p className="df-display m-0 mb-4">
                              1578 Lexington Ave.
                            </p>
                            <div className="mini-stats w-100 d-flex">
                              <div className="w-50">
                                <p className="df-display m-0">$1,400.00</p>
                                <p className="roboto font-400 m-0">
                                  Underwritten
                                </p>
                              </div>
                              <div className="w-50">
                                <p className="df-display m-0">$2,189.00</p>
                                <p className="roboto font-400 m-0">
                                  Market Comps
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-100 px-3 py-3 discount-bar">
                        <p className="roboto w-100 font-400 m-0">
                          Discount to market
                        </p>
                        <p className="df-display w-auto font-green m-0">-36%</p>
                      </div>
                    </div>
                  </Fade>
                  <Fade bottom>
                    <div className="col mx-3">
                      <div className="w-100  top-sect">
                        <div className="w-100 img-sect lex-154" />
                        <div className="w-100 py-4 px-3 stat-sect d-flex">
                          <div className="w-100">
                            <p className="df-display m-0 mb-4">
                              152-154 Lexington Ave.
                            </p>
                            <div className="mini-stats w-100 d-flex">
                              <div className="w-50">
                                <p className="df-display m-0">$1,800.00</p>
                                <p className="roboto font-400 m-0">
                                  Underwritten
                                </p>
                              </div>
                              <div className="w-50">
                                <p className="df-display m-0">$2,512.00</p>
                                <p className="roboto font-400 m-0">
                                  Market Comps
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-100 px-3 py-3 discount-bar">
                        <p className="roboto w-100 font-400 m-0">
                          Discount to market
                        </p>
                        <p className="df-display w-auto font-green m-0">-28%</p>
                      </div>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
          <Footer currentPage={"market"} />
        </div>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default Market;
