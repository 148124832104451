import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import arrow from "../media/arrow.svg";

const boardData = [
  {
    name: "Marc Plotsker",
    title: "Board Member",
    bio:
      "Marc is a senior risk officer at Standard Chartered International commercial bank.<br/><br/>Marc recently retired from an 18 year senior position at the Federal Reserve Bank of New York. Marc has more than two decades of financial services industry experience with a specialization in credit and operational risk management. Marc is a thought leader with strong regulatory banking experience.",
  },
  {
    name: "Scott Panzer",
    title: "Board Member",
    bio:
      "Scott Panzer is a Vice Chairman at Jones Lang Lassalle (JLL). He has been one of the leaders in developing an enhanced level of services to be provided to the real estate industry. Since becoming a service provider in 1992, he has completed over 35 million SF of assignments encompassing all facets of real estate. Scott was JLL’s global MVP for 2011 and 2013.",
  },
];

const teamData = [
  {
    name: "Oren Evanhar",
    title: "Senior Managing Director",
    bio:
      "As President of Evenhar Development, Oren has overseen all phases of project execution. He is responsible for managing all internal as well as contracted employees including comptroller, architects, estimators, designers, engineering and sales staff.<br/><br/>Mr. Evenhar has developed his building experience over the past 20 years, starting his career as a project manager at EH General Contractors and working his way to president Evenhar Development Corporation / Pine Builders Corporation. Mr. Evenhar also dedicates time and resources to various causes that he believes in. He sponsors Home Sweet Home, a non-profit youth organization specializing in helping kids at risk and post risk.",
  },
  {
    name: "Eli Verschleiser",
    title: "Senior Managing Director",
    bio:
      "Mr. Verschleiser through his companies has been directly involved in over $7 Billion of real estate transactions.<br/><br/>He has overseen a private real estate investment banking firm specializing in sourcing complex financial solutions for its client base.<br/><br/>In addition to his investment banking activities, Mr. Verschleiser has experience in the development, management, and sale of millions of square feet of multifamily properties, office buildings, and condominium projects.<br/><br/>In his Philanthropy, Mr. Verschleiser is involved in numerous international and community organizations. Eli is a board member of the American Jewish Congress, the co-founder of Magenu.org, and current Chairman for Our Place, a non-profit organization that provides support, shelter, and counseling for thousands of troubled youth annually.",
  },
  {
    name: "Craig E. Eastmond",
    title: "Managing Director",
    bio:
      "Mr. Craig Eastmond serves as a managing director at the NYC Real Estate Fund.<br/><br/>Mr. Eastmond is responsible for underwriting and executing opportunities in the fund and structuring debt for its projects. Prior to joining the fund, he was a founding principal of Allegiance Realty Corporation, and a Senior Vice President in charge of the Northeast Region of Deutsche Banks' Real Estate Mortgage Conduit.<br/><br/>He has 20 years experience in originating, structuring, underwriting and executing commercial real estate and related transactions. Having done over $10 billion of commercial real estate and related finance deals in numerous capacities, he has a demonstrated track record of executing all manner of real estate transactions from simple fixed rate permanent loans to complex highly structured transactions, such as preferred equity, CDOs & swaps.",
  },
  {
    name: "Michael Riegler",
    title: "Managing Director",
    bio:
      "Mr. Michael Riegler serves as a Managing Director of construction at the NYC Real Estate Fund.<br/><br/>Michael has 30+ years experience in overseeing the construction management for both public and private development firms active in the NYC markets.<br/><br/>Mr. Riegler has an active role in all planning and design, management of procurement, contract administration, change order, requisitions, site supervision, quality control, and regulatory agencies.<br/><br/>While at Tishman Construction, Michael was responsible for Construction Management services for of a number of high-visibility assignments for the special projects division of Tishman Construction including: The University Club; Continental Bank; Swiss Bank; Algonquin Hotel; 919 Third Avenue; and the Prospect Park Zoo.",
  },
  {
    name: "Jonathan Rhodes",
    title: "Director of Operations & Investor Relations",
    bio:
      "Jonathan Rhodes is responsible for managing the fund operations and investor relations.<br/><br/>Previously, Jonathan was Special Assistant to the CEO of Clipper Realty, a NYSE listed real estate trust. He was responsible for implementing the CEO’s vision in various matters, including as the representative for development projects totaling an aggregate value in excess of $1 Billion.<br/><br/>Jonathan is a born and bred resident of New York. Having been brought up in this great city he has a deep routed understanding of the trends that drive local investment strategies.<br/><br/>Jonathan is actively involved in multiple philanthropic organizations, Including Our Place NY, Girls Town Orphanage and Life Vest Inside.",
  },
  {
    name: "Martin Teitelbaum",
    title: "Business Development Director",
    bio:
      "Martin Teitelbaum, serves as the business development director for the fund and its affiliates.<br/><br/>Mr. Teitelbaum is an executive with a 25+ year successful career in Sales and Business Development working in several segments and industries, from high technology companies to large real estate projects.<br/><br/>Martin graduated in Business Administration with specialization in International Trade and Economics. Martins’ strong performance and great knowledge in Central and Latin America markets has lead him to be a sought after executive in the region.<br/><br/>Martin was born in Sao Paulo - Brazil, and moved to Israel with his family in 2010.<br/><br/>Volunteer at Jewish Agency and at Beit Brasil, Israeli NGO that helps new-immigrants from Brazil.",
  },
];

class Team extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: props.auth,
      memberView: -1,
      boardView: -1,
    };

    this.boardToggle = this.boardToggle.bind(this);
    this.bioToggle = this.bioToggle.bind(this);
  }

  bioToggle(m) {
    if (m === this.state.memberView) {
      this.setState({ memberView: -1 });
    } else {
      this.setState({ memberView: m });
    }
  }

  boardToggle(m) {
    if (m === this.state.boardView) {
      this.setState({ boardView: -1 });
    } else {
      this.setState({ boardView: m });
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    if (this.state.auth) {
      return (
        <div className="body-team">
          <Navbar auth={this.state.auth} currentPage={"team"} />
          <div className="team-container w-100">
            <div className="container">
              <div className="row">
                <div className="col-md">
                  <h1 className="playfair page-title mt-4 mb-3 my-md-5">
                    Team
                  </h1>

                  {teamData.map((person, key) => (
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        this.bioToggle(key);
                      }}
                      className="team-member transition-all py-3 w-100"
                    >
                      <div className="w-100 summary row">
                        <div className="col">
                          <p className="playfair m-0 transition-all">
                            {person.name}
                          </p>
                          <p className="roboto m-0 transition-all">
                            {person.title}
                          </p>
                        </div>
                        <div className="col-auto d-flex">
                          <button className="team-b">
                            <img
                              alt=""
                              className={
                                this.state.memberView === key
                                  ? "flip transition-all"
                                  : "transition-all"
                              }
                              src={arrow}
                            />
                          </button>
                        </div>
                      </div>
                      <div
                        className={`w-100 bio transition-all ${
                          this.state.memberView === key ? "expand-team" : ""
                        }`}
                      >
                        <p
                          className="w-100 font-300 roboto mt-3"
                          dangerouslySetInnerHTML={{ __html: person.bio }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="col-md">
                  <h1 className="playfair page-title mt-5 mb-1 mt-md-5">
                    Board of Advisors
                  </h1>
                  <a className="no-a playfair font-white mb-5 mb-md-0">
                    (in-formation)
                  </a>

                  {boardData.map((person, key) => (
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        this.boardToggle(key);
                      }}
                      className="team-member transition-all py-3 w-100"
                    >
                      <div className="w-100 summary row">
                        <div className="col">
                          <p className="playfair m-0 transition-all">
                            {person.name}
                          </p>
                          <p className="roboto m-0 transition-all">
                            {person.title}
                          </p>
                        </div>
                        <div className="col-auto d-flex">
                          <button className="team-b">
                            <img
                              alt=""
                              className={
                                this.state.boardView === key
                                  ? "flip transition-all"
                                  : "transition-all"
                              }
                              src={arrow}
                            />
                          </button>
                        </div>
                      </div>
                      <div
                        className={`w-100 bio transition-all ${
                          this.state.boardView === key ? "expand-team" : ""
                        }`}
                      >
                        <p
                          className="w-100 font-300 roboto mt-3"
                          dangerouslySetInnerHTML={{ __html: person.bio }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <Footer currentPage={"market"} />
        </div>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default Team;
