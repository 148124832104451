//Library imports
import React, { Component } from "react";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useParams,
} from "react-router-dom";
import firebase from "firebase/app";
import "firebase/auth";

// Media imports

import "./App.scss";

//Component Imports

//Page imports
import Home from "./pages/Home";
import FundOverview from "./pages/FundOverview";
import Portfolio from "./pages/Portfolio";
import Market from "./pages/Market";
import Team from "./pages/Team";
import Documents from "./pages/Documents";

import PassVerify from "./pages/PassVerify";

//Firebase API
var firebaseConfig = {
  apiKey: "AIzaSyCizOLdaEwGUnVRVFojgUdliwozUxE4_Nk",
  authDomain: "nycref-client-build.firebaseapp.com",
  databaseURL: "https://nycref-client-build.firebaseio.com",
  projectId: "nycref-client-build",
  storageBucket: "nycref-client-build.appspot.com",
  messagingSenderId: "293004496791",
  appId: "1:293004496791:web:a9af3e823557f3b3e7bed2",
  measurementId: "G-QETEJG0JMM",
};

const backend = firebase.initializeApp(firebaseConfig);
const backendAuth = backend.auth();
backendAuth.setPersistence(firebase.auth.Auth.Persistence.NONE);

function UserVerify() {
  let { handle } = useParams();

  return <h1>{handle}</h1>;
}

export default class App extends Component {
  constructor() {
    super();

    this.state = {
      auth: false,
      loading: true,
      currentPage: "home",
      currentUser: {
        displayName: "",
        email: "",
      },
      userError: "",
      isAccountVerify: false,
      loggedVerify: "",
      accountCreated: false,
    };

    this.signIn = this.signIn.bind(this);
    this.createAccount = this.createAccount.bind(this);
    this.firstAuth = this.firstAuth.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.passwordModal = this.passwordModal.bind(this);
    this.createAccount = this.createAccount.bind(this);
  }

  createAccount(email, pass, callback) {
    callback("");
    backendAuth
      .createUserWithEmailAndPassword(email, pass)
      .catch(function (error) {
        callback(error.message);
      });
  }

  componentDidMount() {
    console.log(this.state.testKey);

    backendAuth.onAuthStateChanged((userAuth) => {
      if (userAuth !== null) {
        this.setState({
          auth: true,
          loading: false,
        });
      }
    });
  }

  changePassword(newPass) {}

  firstAuth(email, password) {}

  signIn(email, password) {
    if (email.includes("@") && email.includes(".")) {
      backendAuth.signInWithEmailAndPassword(email, password).catch((error) => {
        if (error.code === "auth/user-not-found") {
          this.setState({
            userError:
              "There is no user with the given email address. Please recheck your credentials.",
          });
        } else if (error.code === "auth/wrong-password") {
          this.setState({
            userError:
              "The password is invalid. Please recheck your credentials.",
          });
        }
      });
    } else {
      this.setState({
        userError: "That email is invalid. Please type a proper email.",
      });
    }
  }

  componentWillUnmount() {
    backendAuth.signOut();
  }

  passwordModal(props) {
    let { handle } = useParams();

    console.log(props);

    if (props.auth) {
      this.setState({ accountCreated: true });
      return <Redirect to="/" />;
    } else {
      return (
        <PassVerify
          email={handle}
          firstAuth={props.firstAuth}
          changePassword={props.changePassword}
          createAccount={props.createAccount}
          auth={props.auth}
          userError={props.userError}
        />
      );
    }
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route
            path="/account-verify/:handle"
            children={
              <this.passwordModal
                firstAuth={this.firstAuth}
                changePassword={this.changePassword}
                createAccount={this.createAccount}
                userError={this.state.userError}
                auth={this.state.auth}
              />
            }
          />
          <Route
            path="/"
            component={() => (
              <Home
                accountCreated={this.state.accountCreated}
                auth={this.state.auth}
                pageSetter
                userError={this.state.userError}
                loginFunction={this.signIn}
              />
            )}
            exact
          />
          <Route
            path="/fund"
            component={() => <FundOverview auth={this.state.auth} />}
            exact
          />
          <Route
            path="/portfolio"
            component={() => <Portfolio auth={this.state.auth} />}
            exact
          />
          <Route
            path="/market"
            component={() => <Market auth={this.state.auth} />}
            exact
          />

          <Route
            path="/team"
            component={() => <Team auth={this.state.auth} />}
            exact
          />
          <Route component={() => <Redirect to="/" />} />
        </Switch>
      </Router>
    );
  }
}
