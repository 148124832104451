import React, { Component } from "react";
import close from "../media/closex.svg";

class PropView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      propertyData: props.data,
      closeFunction: props.close,
      imageClass: "img img-b-" + String(props.imageClass),
      passID: props.passID,
      pdf: props.pdf,
      currentRender: 0,
      width: 0,
      height: 0,
    };
    console.log(props);

    this.changeRender = this.changeRender.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  changeRender(render) {
    this.setState({ currentRender: render });
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    return (
      <div id={this.state.passID} className="prop-view-overlay d-flex">
        <div className="prop-view-mobile w-100 py-5 my-4 m-only">
          <div className="container">
            <h1
              dangerouslySetInnerHTML={{
                __html: this.state.propertyData.address,
              }}
              className="playfair"
            />
            <p
              dangerouslySetInnerHTML={{
                __html: this.state.propertyData.description,
              }}
              className="roboto font-300 mt-3"
            ></p>

            {this.state.pdf.includes("154 Lexington") ? (
              <a />
            ) : (
              <a
                href={process.env.PUBLIC_URL + "/pdf/" + this.state.pdf}
                target="_blank"
                rel="noopener noreferrer"
                className="prop-memo-link btn-a roboto font-700 py-2 px-3 transition-all my-4"
              >
                Transaction Memorandum
              </a>
            )}

            <table className="w-100">
              <tr className="stat-table-title">
                <td />
                <td>
                  <p className="stat-table-title roboto font-700 text-center">
                    Amount
                  </p>
                </td>
                <td>
                  <p className="stat-table-title roboto font-700 text-center">
                    PSF
                  </p>
                </td>
              </tr>

              {this.state.propertyData.stats.map((stat) => (
                <tr className="w-100 table-stats-now">
                  <td>
                    <p className="roboto font-400 text-left">{stat.name}</p>
                  </td>
                  <td>
                    <p className="roboto font-400 text-right">{stat.amount}</p>
                  </td>
                  <td>
                    <p className="roboto font-400 text-right">{stat.psf}</p>
                  </td>
                </tr>
              ))}
            </table>

            <table className="w-100">
              {this.state.propertyData.stats2.map((stat) => (
                <tr
                  className={`w-100 table-stats-now ${
                    stat.name === "Proj. Gross Profit" ? "grey-boy" : ""
                  }`}
                >
                  <td>
                    <p className="roboto font-400 text-left">{stat.name}</p>
                  </td>
                  <td>
                    <p className="roboto font-400 text-right">{stat.right}</p>
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </div>
        <div className="prop-view-container d-only">
          <a
            onClick={this.state.closeFunction}
            className="close-button transition-all"
          >
            <img className="transition-all" src={close} />
          </a>
          <div className="row w-100">
            <div
              className="col-md img-col"
              className={`col-md img-col ${this.state.imageClass}`}
            ></div>

            <div className="col-md content-col">
              <h1
                dangerouslySetInnerHTML={{
                  __html: this.state.propertyData.address,
                }}
                className="playfair py-3 font-black"
              ></h1>
              <div className="row w-100 p-3 button-col">
                <a
                  onClick={() => {
                    this.changeRender(0);
                  }}
                  className={`btn-button transition-all mr-3 col-auto roboto font-400 ${
                    this.state.currentRender === 0 ? "filled" : ""
                  }`}
                >
                  Description
                </a>
                <a
                  onClick={() => {
                    this.changeRender(1);
                  }}
                  className={`btn-button transition-all mr-3 col-auto roboto font-400 ${
                    this.state.currentRender === 1 ? "filled" : ""
                  }`}
                >
                  Financials
                </a>
                <a
                  onClick={() => {
                    this.changeRender(2);
                  }}
                  className={`btn-button transition-all col-auto roboto font-400 ${
                    this.state.currentRender === 2 ? "filled" : ""
                  }`}
                >
                  Map
                </a>
                {this.state.pdf.includes("154 Lexington") ? (
                  ""
                ) : (
                  <a
                    href={process.env.PUBLIC_URL + "/pdf/" + this.state.pdf}
                    target="_blank"
                    className={`btn-button transition-all mt-3 col-auto roboto font-400`}
                  >
                    Transaction Memorandum
                  </a>
                )}
              </div>
              <div className="w-100 inner-data">
                <p
                  dangerouslySetInnerHTML={{
                    __html: this.state.propertyData.description,
                  }}
                  className={`roboto description-width font-300 my-3 ${
                    this.state.currentRender === 0 ? "" : "display-none"
                  }`}
                ></p>
                <div className="w-100">
                  <table
                    className={`w-100 ${
                      this.state.currentRender === 1 ? "" : "display-none"
                    }`}
                  >
                    <tr className="stat-table-title">
                      <td />
                      <td>
                        <p className="stat-table-title roboto font-700 text-center">
                          Amount
                        </p>
                      </td>
                      <td>
                        <p className="stat-table-title roboto font-700 text-center">
                          PSF
                        </p>
                      </td>
                    </tr>

                    {this.state.propertyData.stats.map((stat) => (
                      <tr className="w-100 table-stats-now pb-3">
                        <td>
                          <p
                            className={`roboto font-400 text-left ${
                              stat.name === "Total Est. Cost" ? "font-700" : ""
                            }`}
                          >
                            {stat.name}
                          </p>
                        </td>
                        <td>
                          <p
                            className={`roboto font-400 text-right ${
                              stat.name === "Total Est. Cost" ? "font-700" : ""
                            }`}
                          >
                            {stat.amount}
                          </p>
                        </td>
                        <td>
                          <p
                            className={`roboto font-400 text-right ${
                              stat.name === "Total Est. Cost" ? "font-700" : ""
                            }`}
                          >
                            {stat.psf}
                          </p>
                        </td>
                      </tr>
                    ))}
                  </table>

                  <table
                    className={`w-100 ${
                      this.state.currentRender === 1 ? "" : "display-none"
                    }`}
                  >
                    {this.state.propertyData.stats2.map((stat) => (
                      <tr
                        className={`w-100 table-stats-now pb-3 ${
                          stat.name === "Proj. Gross Profit" ? "greyed" : ""
                        }`}
                      >
                        <td>
                          <p className="roboto font-400 text-left">
                            {stat.name}
                          </p>
                        </td>
                        <td>
                          <p className="roboto font-400 text-right">
                            {stat.right}
                          </p>
                        </td>
                      </tr>
                    ))}
                  </table>
                </div>

                <div
                  className={`w-100 gmap-code ${
                    this.state.currentRender === 2 ? "" : "display-none"
                  }`}
                >
                  <iframe
                    src={this.state.propertyData.map}
                    width="600"
                    height="300"
                    frameborder="0"
                    style={{ border: 0 }}
                    allowfullscreen=""
                    aria-hidden="false"
                    tabindex="0"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PropView;
