import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import circle from "../media/circle.svg";
import { Fade } from "react-reveal";

class FundOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: props.auth,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    if (this.state.auth) {
      return (
        <div className="body-fund">
          <Navbar auth={this.state.auth} currentPage={"fund"} />
          <div className="container">
            <div className="row">
              <div className="col-md">
                <h1 className="playfair font-black">
                  <a className="no-a d-only font-black">NYC</a>{" "}
                  <a className="no-a m-only font-black">New York City</a>{" "}
                  <a className="no-a font-orange">
                    <br className="m-only" />
                    Real Estate <br className="m-only" />
                    Fund LLC
                  </a>
                </h1>
              </div>
              <div className="col-md py-3 py-md-0">
                <p className="roboto font-300">
                  The NYC Real Estate Fund (the “Fund”) has been established to
                  capitalize on the unique and proprietary relationship
                  developed between the management of the Fund (the
                  “Management”) and the Not for Profit sector in New York City.
                  <br />
                  <br />
                  Over the past few years Management has created and nurtured
                  close relationships with schools, synagogues, churches,
                  community centers, and many members of the clergy in New York
                  City.
                </p>
              </div>
              <div className="col-md">
                <p className="roboto font-300">
                  The relationships have led Management to contract and acquire
                  property in Manhattan at extremely discounted pricing.
                  Management is in a unique position to continue to purchase
                  these deeply discounted properties.
                  <br />
                  <br />
                  The five year closed end Fund is raising up to $100 million to
                  invest into these development projects. The fund expects
                  investor returns in excess of a 20% IRR.
                </p>
              </div>
            </div>

            <div className="overview-section w-100 mt-1 mt-md-5">
              <img alt="" className="circle" src={circle} />

              <div className="details">
                <Fade right>
                  <h1 className="playfair font-black mb-5">
                    Fund
                    <br />
                    Overview
                  </h1>
                </Fade>
                <Fade right>
                  <div className="w-100 row-container">
                    <div className="row w-100">
                      <div className="col d-col">
                        <p className="font-black df-display mb-1">250,000+</p>
                        <p className="roboto font-300 m-0">
                          BSF Under Development
                        </p>
                      </div>
                      <div className="col d-col">
                        <p className="font-black df-display mb-1">$215</p>
                        <p className="roboto font-300 m-0">Average Cost PBSF</p>
                      </div>
                      <div className="col d-col">
                        <p className="font-black df-display mb-1">20%+</p>
                        <p className="roboto font-300 m-0">
                          Projected fund IIR
                        </p>
                      </div>
                      <div className="col-auto d-col">
                        <p className="font-black df-display mb-1">2.8x</p>
                        <p className="roboto font-300 m-0">
                          Projected equity multiple
                        </p>
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>

              <img
                alt=""
                className="d-only w-100"
                src={require("../media/about-fund.jpg")}
              />
              <img
                alt=""
                className="m-only mt-3 fund-mobile w-100"
                src={require("../media/about-fund-mobile.jpg")}
              />
            </div>

            <div className="highlights-section w-100 mt-1">
              <Fade left>
                <div className="title-text w-100 my-5">
                  <img alt="" className="circle" src={circle} />
                  <h1 className="playfair font-black">
                    Fund
                    <br />
                    <a className="font-black no-a pl-0 pl-md-5">Highlights</a>
                  </h1>
                </div>
              </Fade>
              <div className="row w-100">
                <Fade left cascade>
                  <div className="col-md">
                    <p className="df-display font-black">01</p>
                    <p className="roboto font-400">
                      Four to five year exit selling into a strong market of
                      2025-2026.
                    </p>
                  </div>
                  <div className="col-md">
                    <p className="df-display font-black">02</p>
                    <p className="roboto font-400">
                      Strong management and advisory team with over 100 years
                      combined experience and deep local knowledge.
                    </p>
                  </div>
                  <div className="col-md">
                    <p className="df-display font-black">03</p>
                    <p className="roboto font-400">
                      Land acquisition costs are below $215 BSF, an extremely
                      attractive basis at 60%+ below market (Oct. 2020).
                    </p>
                  </div>
                  <div className="col-md">
                    <p className="df-display font-black">04</p>
                    <p className="roboto font-400">
                      A NNN Lease with a major NYC Hospital with $4+ million
                      annual income.
                    </p>
                  </div>
                  <div className="col-md">
                    <p className="df-display font-black">05</p>
                    <p className="roboto font-400">
                      Pre-leased and pre-sold commercial space lowers risk
                      profile.
                    </p>
                  </div>
                  <div className="col-md">
                    <p className="df-display font-black">06</p>
                    <p className="roboto font-400">
                      Conservative condominium projections well below the
                      comparable product.
                    </p>
                  </div>

                  <div className="col-md">
                    <p className="df-display font-black">07</p>
                    <p className="roboto font-400">
                      NoMad, the East Village and other attractive Manhattan
                      neighborhoods with extremely low development
                      opportunities.
                    </p>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
          <Footer currentPage={"fund"} />
        </div>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default FundOverview;
