import React, { Component } from "react";
import { Link } from "react-router-dom";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const philContainer = [
  {
    image: "Discount",
    text:
      "Acquire deeply discounted developable properties protecting downside risk",
  },
  {
    image: "Analytical",
    text:
      "Analytical approach with an emphasis on intrinsic value and return on investment",
  },
  {
    image: "Transaction",
    text: "Concentrate on a limited number of transactions simultaneously",
  },
  {
    image: "Located",
    text:
      "Focus on high quality, well-located properties in supply constrained neighborhoods",
  },
  {
    image: "Value",
    text: "Hands-on approach to value creation",
  },
  {
    image: "TransactionOG",
    text: "Proactive transaction origination",
  },
  {
    image: "protection",
    text:
      "Rigorous and conservative underwriting, with a focus on downside protection and long-term capital preservation",
  },
  {
    image: "structures",
    text: "Prudent capital structures",
  },
];

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      auth: props.auth,
      typedEmail: "",
      typedPass: "",
      loginFunction: props.loginFunction,
      userError: props.userError,
      navColor: false,
      accountCreated: props.accountCreated,
    };

    this.handleUser = this.handleUser.bind(this);
    this.handlePass = this.handlePass.bind(this);
    this.signInClick = this.signInClick.bind(this);
    this.handleScoll = this.handleScoll.bind(this);
  }

  signInClick(e) {
    e.preventDefault();
    this.state.loginFunction(this.state.typedEmail, this.state.typedPass);
  }

  handleUser(e) {
    e.preventDefault();
    this.setState({ typedEmail: e.target.value });
  }

  handlePass(e) {
    e.preventDefault();
    this.setState({ typedPass: e.target.value });
  }

  handleScoll() {
    if (window.pageYOffset > 50) {
      this.setState({ navColor: true });
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    if (!this.state.auth) {
      window.onscroll = this.handleScoll;
    }
  }

  componentWillUnmount() {
    window.onscroll = null;
  }

  render() {
    if (this.state.auth) {
      return (
        <div className="body-home-auth">
          <Navbar
            auth={this.state.auth}
            navColor={this.state.navColor}
            currentPage={"home"}
          />

          <div className="container auth-body">
            <div className="w-100">
              {this.state.accountCreated ? (
                <p className="roboto font-400 font-orange my-3">
                  Your account has been successfully created.
                </p>
              ) : (
                <div />
              )}
              <h1 className="title-text playfair pb-3 font-white">
                There is a reason
                <br className="d-only" />
                <span className="m-only"> </span>
                NYC is called the
                <br className="d-only" />
                <span className="m-only"> </span>
                capital of the world
              </h1>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="full-body">
          <div id="home-top" className="body-home">
            <Navbar
              auth={this.state.auth}
              navColor={this.state.navColor}
              currentPage={"home"}
            />
            <div className="container">
              <div className="row">
                <div className="col-md-auto title-col">
                  <div className="ct">
                    <h1 className="playfair font-white">
                      There is a reason
                      <br className="d-only" />
                      <span className="m-only"> </span>
                      NYC is called the
                      <br className="d-only" />
                      <span className="m-only"> </span>
                      capital of the world
                    </h1>

                    <p className="roboto font-400 my-4 mt-md-5 mb-md-0 font-white">
                      Access to this website is by invitation only.
                      <br />
                      Please enter your email and password to enter the site.
                    </p>
                  </div>
                </div>
                <div className="col-md">
                  <div className="login-modal w-100 px-3 py-3">
                    <form onSubmit={this.signInClick}>
                      <p className="playfair p-0 my-2 font-black">
                        Email Address
                      </p>
                      <input
                        placeholder="hello@gmail.com"
                        className="w-100 roboto p-3 my-0"
                        type="text"
                        value={this.state.typedEmail}
                        onChange={this.handleUser}
                      />

                      <p className="playfair p-0 my-2 font-black mt-4">
                        Password
                      </p>
                      <input
                        placeholder=""
                        className="w-100 roboto p-3 my-0"
                        type="password"
                        value={this.state.typedPass}
                        onChange={this.handlePass}
                      />
                      <p className="roboto text-center pt-3 font-orange m-0 font-700 error-text">
                        {this.state.userError}
                      </p>
                      <button
                        type="submit"
                        className="playfair w-100 p-3 mt-4 transition-all"
                      >
                        Log in
                      </button>
                    </form>
                    <p className="font-black roboto font-300 mt-4 more-help-text">
                      For more information on the NYC Real Estate Fund LLC,
                      please email us at{" "}
                      <a
                        href="mailto:investors@nycref.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        investors@nycref.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="body-home-about">
            <div className="container py-5">
              <div className="row mb-5 pb-5">
                <div className="col-md">
                  <h1 id="about" className="playfair mb-5 font-white">
                    About
                  </h1>
                </div>
                <div className="col-md">
                  <p className="roboto font-300">
                    NYC Real Estate Fund LLC is a vertically integrated private
                    equity real estate fund based in New York City. The firm
                    specializes in small to middle-market transactions in all
                    sectors with little to no market cycle correlation.
                  </p>
                </div>
                <div className="col-md">
                  <p className="roboto font-300">
                    Together management has been responsible for the
                    acquisition, development and finance of over $10 billion of
                    real estate assets located in the United States through over
                    150 separate transactions.
                  </p>
                </div>
              </div>

              <div className="row phil-row">
                <div className="col-md-auto">
                  <h1
                    id="philosophy font-white"
                    className="playfair font-white mb-5"
                  >
                    Philosophy
                  </h1>
                </div>
                <div className="col-md icon-col">
                  <div className="row w-100">
                    {philContainer.map((col) => (
                      <div className="col-md mb-5 mb-md-4">
                        <img
                          className={col.image}
                          src={require("../media/" + col.image + ".png")}
                        />
                        <p className="mt-3 roboto font-white font-300">
                          {col.text}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer currentPage={"home"} />
        </div>
      );
    }
  }
}

export default Home;
