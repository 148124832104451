import React, { Component } from "react";
import { Redirect, useParams } from "react-router-dom";

export default class PassVerify extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstAuth: this.props.firstAuth,
      changePassword: this.props.changePassword,
      userEmail: this.props.email,
      createAccount: this.props.createAccount,
      typedPass: "",
      typedVerify: "",
      userError: "",
      auth: this.props.auth,
      userError: this.props.userError,
    };

    this.signInClick = this.signInClick.bind(this);
    this.userHandle = this.userHandle.bind(this);
    this.passHandle = this.passHandle.bind(this);
    this.handleVerify = this.handleVerify.bind(this);
    this.setError = this.setError.bind(this);
  }

  setError(error) {
    this.setState({ userError: error });
    console.log(this.state.userError);
  }

  signInClick(e) {
    e.preventDefault();
    this.state.createAccount(
      this.state.userEmail,
      this.state.typedPass,
      this.setError
    );
  }

  userHandle(e) {
    e.preventDefault();
    this.setState({ userEmail: e.target.value });
  }

  passHandle(e) {
    e.preventDefault();
    this.setState({ typedPass: e.target.value });
  }

  handleVerify(e) {
    e.preventDefault();
    this.setState({ typedVerify: e.target.value });
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    if (this.state.auth) {
      return <Redirect to="/" />;
    } else {
      return (
        <div className="full-pass-body">
          <div className="container">
            <div className="verify-modal w-100 px-3 py-3">
              <h1 className="playfair font-orange mb-5 text-center">
                Create your NYC-REF Account
              </h1>
              <form onSubmit={this.signInClick}>
                <p className="playfair p-0 my-2 font-black">Email Address</p>
                <input
                  placeholder="hello@gmail.com"
                  className="w-100 roboto p-3 my-0"
                  type="text"
                  value={this.state.userEmail}
                  onChange={this.userHandle}
                />

                <p className="playfair p-0 my-2 font-black mt-4">
                  New Password
                </p>
                <input
                  placeholder=""
                  className="w-100 roboto p-3 my-0"
                  type="password"
                  value={this.state.typedPass}
                  onChange={this.passHandle}
                />
                <p className="roboto text-center pt-3 font-orange m-0 font-700 error-text">
                  {this.state.userError}
                </p>
                <button
                  type="submit"
                  className="playfair w-100 p-3 mt-4 transition-all"
                >
                  Signup
                </button>
              </form>
              <p className="font-black roboto font-300 mt-4 more-help-text">
                For more information on the NYC Real Estate Fund LLC, please
                email us at{" "}
                <a
                  href="mailto:investors@nycref.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  investors@nycref.com
                </a>
              </p>
            </div>
          </div>
        </div>
      );
    }
  }
}
