import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import dots from "../media/dots.svg";

class Documents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: props.auth,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    if (this.state.auth) {
      return (
        <div className="body-documents">
          <Navbar auth={this.state.auth} currentPage={"documents"} />
          <div className="fund-docs-container">
            <div className="container">
              <div className="w-100 text-right d-only">
                <img alt="" src={dots} />
              </div>
              <h1 className="title playfair my-4 mb-5">Fund Documents</h1>
              <div className="row">
                <div className="col-md my-2 my-md-0">
                  <a className="btn roboto py-3 p-md-3 font-700 transition-all">
                    NYC Real Estate Find Private{" "}
                    <span className="m-only">Plac.</span>{" "}
                    <span className="d-only">Placement</span> Memorandum
                  </a>
                </div>
                <div className="col-md my-2 my-md-0">
                  <a className="btn roboto py-3 p-md-3 font-700 transition-all">
                    NYC Real Estate Fund Subscription Booklet
                  </a>
                </div>
              </div>
              <div className="row mt-md-3">
                <div className="col-md my-2 my-md-0">
                  <a className="btn roboto py-3 p-md-3 font-700 transition-all">
                    NYC Real Estate Fund Offering Memorandum
                  </a>
                </div>
                <div className="col-md my-2 my-md-0">
                  <a className="btn roboto py-3 p-md-3 font-700 transition-all">
                    NYC Real Estate Fund Operating Agreement
                  </a>
                </div>
              </div>
            </div>
          </div>
          <Footer currentPage={"documents"} />
        </div>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default Documents;
