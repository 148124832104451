import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Link, animateScroll as scroll } from "react-scroll";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import PropView from "../components/PropView";

import dots from "../media/dots.svg";

import east7 from "../media/prop/east7.jpg";
import lex1578 from "../media/prop/1578lex.jpg";
import lex154 from "../media/prop/154lex.jpg";

const portfolioData = [
  {
    picture: east7,
    map:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2439.0579383804916!2d-73.97933775197048!3d40.7242025914181!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25979ef01779f%3A0x9872129b3d88ff96!2s245%20E%207th%20St%2C%20New%20York%2C%20NY%2010009!5e0!3m2!1sen!2sus!4v1602631878320!5m2!1sen!2sus",
    pdf: "245-247 East 7th Street - Transaction Memorandum.pdf",
    address: "245-247 <br/>East 7th St.",
    description:
      "245-247 East 7th Street is a to-be-built 44,695 SF seven story mixed use building. The site is located in the unique Manhattan neighborhood of the east village between Avenues C and D  on East 7th Street.<br/><br/>The building is preliminarily designed to offer 38 residential condominiums, and a 7,565 SF community center.",
    stats: [
      {
        name: "Acquisition Cost",
        amount: "$8,023,624",
        psf: "$180",
      },
      {
        name: "Est. Hard Cost",
        amount: "$13,409,000",
        psf: "$300",
      },
      {
        name: "Est. Soft Cost",
        amount: "$2,846,000",
        psf: "$64",
      },
      {
        name: "Est. Financing Cost",
        amount: "$4,054,000",
        psf: "$91",
      },
      {
        name: "Total Est. Cost",
        amount: "$28,333,000",
        psf: "$634",
      },
      {
        name: "Est. Debt",
        amount: "$18,000,000",
        psf: "64%",
      },
      {
        name: "Est. Equity",
        amount: "10,333,000",
        psf: "36%",
      },
    ],
    stats2: [
      {
        name: "Proj. Gross Profit",
        right: "$15MM - $17MM",
      },
      {
        name: "Proj. Return on Equity",
        right: "25% - 30%",
      },
      {
        name: "Proj. Equity Multiple",
        right: "2.2X - 2.5X",
      },
    ],
  },
  {
    picture: lex1578,
    map:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3020.7786457382963!2d-73.95116698459832!3d40.788880279323564!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c258a7ec6312d3%3A0x8004581130292177!2s1578%20Lexington%20Ave%2C%20New%20York%2C%20NY%2010029!5e0!3m2!1sen!2sus!4v1602632914822!5m2!1sen!2sus",
    pdf: "1578 Lexington Avenue - Transaction Memorandum.pdf",
    address: "1578 Lexington Ave.",
    description:
      "1578 Lexington is a to-be-built 161,000 SF eleven story mixed use building. The site is located in the upper Carnegie Hill section of Manhattan at the intersection of  Lexington Avenue and E 101st street.<br/><br/>As currently designed, 91,310 Sf. is a triple net (NNN) leased to Mount Sinai Hospital for over 30 years. The top two floors will offer 30 condominiums totaling 25,320 SF. A 27,900 SF of community center space, of which 17,900 SF is pre-sold to the Children Aide Society for approximately $13MM; A 4,200 SF street level retail.",
    stats: [
      {
        name: "Acquisition Cost",
        amount: "$27,268,000",
        psf: "$169",
      },
      {
        name: "Est. Hard Cost",
        amount: "$62,797,000",
        psf: "$390",
      },
      {
        name: "Est. Soft Cost",
        amount: "$9,453,000",
        psf: "$59",
      },
      {
        name: "Est. Financing Cost",
        amount: "$20,437,000",
        psf: "$79",
      },
      {
        name: "Total Est. Cost",
        amount: "$119,955,000",
        psf: "$734",
      },
      {
        name: "Est. Debt",
        amount: "$94,500,000",
        psf: "79%",
      },
      {
        name: "Est. Equity",
        amount: "25,455,000",
        psf: "21%",
      },
    ],
    stats2: [
      {
        name: "Proj. Gross Profit",
        right: "$40MM - $45MM",
      },
      {
        name: "Proj. Return on Equity",
        right: "22% - 25%",
      },
      {
        name: "Proj. Equity Multiple",
        right: "2.4X - 2.8X",
      },
    ],
  },
  {
    picture: lex154,
    map:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.8256549756743!2d-73.98405008459967!3d40.74386167932842!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2590815fd78af%3A0x27c7a911454533b!2s154%20Lexington%20Ave%2C%20New%20York%2C%20NY%2010016!5e0!3m2!1sen!2sus!4v1602632981271!5m2!1sen!2sus",
    pdf: "154 Lexington Avenue - Transaction Memorandum.pdf",
    address: "154 Lexington Ave.",
    description:
      "152-154 Lexington Avenue is a to-be-built 58,000 SF nine story mixed use building. The site is located in the NoMad / Murray Hill neighborhood on the corner of East 30th & Lexington Ave.<br/><br/>The building is designed to offer 62 residential units, 2,225 SF of retail and an 5,531 SF community center.",
    stats: [
      {
        name: "Acquisition Cost",
        amount: "$15,500,000",
        psf: "$260",
      },
      {
        name: "Est. Hard Cost",
        amount: "$16,848,000",
        psf: "$291",
      },
      {
        name: "Est. Soft Cost",
        amount: "$3,694,000",
        psf: "$64",
      },
      {
        name: "Est. Financing Cost",
        amount: "$3,986,000",
        psf: "$69",
      },
      {
        name: "Total Est. Cost",
        amount: "$39,578,000",
        psf: "$683",
      },
      {
        name: "Est. Debt",
        amount: "$28,474,000",
        psf: "72%",
      },
      {
        name: "Est. Equity",
        amount: "$11,104,000",
        psf: "28%",
      },
    ],

    stats2: [
      {
        name: "Proj. Gross Profit",
        right: "$19MM - $29MM",
      },
      {
        name: "Proj. Return on Equity",
        right: "36% - 50%",
      },
      {
        name: "Proj. Equity Multiple",
        right: "2.7X - 3.6X",
      },
    ],
  },
];

class Portfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: props.auth,
      visible: 1,
      openProp: -1,
    };

    this.refList = [];

    this.toggleProp = this.toggleProp.bind(this);
    this.propView = this.propView.bind(this);
    this.mobileScroll = this.mobileScroll.bind(this);
    this.mobileProp = this.mobileProp.bind(this);
  }

  mobileScroll(e) {
    e.preventDefault();
    if (this.div.scrollLeft / this.div.offsetWidth > 0.4) {
      if (this.div.scrollLeft / this.div.offsetWidth > 1) {
        this.setState({ visible: 3 });
      } else {
        this.setState({ visible: 2 });
      }
    } else {
      this.setState({ visible: 1 });
    }
  }

  toggleProp(e) {
    e.preventDefault();
    this.setState({ openProp: -1 });
  }

  mobileProp(e) {
    console.log(this.RefList[e].current.offsetTop);
    window.scrollTo(0, this.RefList[e].current.offsetTop);
  }

  propView(e) {
    console.log(e);
    this.setState({ openProp: e });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.state.auth) {
      this.div.addEventListener("scroll", this.mobileScroll);
    }
  }

  render() {
    if (this.state.auth) {
      return (
        <div
          className={`body-portfolio ${
            this.state.openProp > -1 ? "abs-no-scroll" : ""
          }`}
        >
          <Navbar auth={this.state.auth} currentPage={"portfolio"} />
          <div className="head-container w-100">
            <div className="container">
              <div className="row w-100 pt-5 py-md-5">
                <div className="col-auto title-col">
                  <div className="inner">
                    <h1 className="playfair my-5">Portfolio</h1>
                    <img alt="" className="mt-3" src={dots} />
                  </div>
                </div>
                <div className="col d-only prop-col">
                  <div className="prop-row row">
                    {portfolioData.map((prop, key) => (
                      <div className="col">
                        <img alt="" className="w-100" src={prop.picture} />

                        <div className="w-100 info-box mt-4 pt-2">
                          <p
                            dangerouslySetInnerHTML={{ __html: prop.address }}
                            className="df-display"
                          ></p>
                          <a
                            onClick={() => {
                              this.propView(key);
                            }}
                            className="btn-black playfair text-center py-2 px-5 transition-all"
                          >
                            More info
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="scroll-position ml-3 mb-3 w-50 row">
              <div
                className={`col ${
                  this.state.visible === 1 ? "active-white" : ""
                }`}
              />
              <div
                className={`col mx-2 ${
                  this.state.visible === 2 ? "active-white" : ""
                }`}
              />
              <div
                className={`col ${
                  this.state.visible === 3 ? "active-white" : ""
                }`}
              />
            </div>
            <div
              className="m-only scroll-wrapper w-100n"
              ref={(div) => (this.div = div)}
            >
              <div className="scroll-container pl-3 row">
                {portfolioData.map((prop, key) => (
                  <div className="scroll-prop col">
                    <img alt="" src={prop.picture} className="w-100" />
                    <div className="w-100 info-box mt-4 pt-2">
                      <p
                        dangerouslySetInnerHTML={{ __html: prop.address }}
                        className="df-display"
                      ></p>
                      <button
                        key={key}
                        className="btn-black playfair text-center py-2 px-5 transition-all"
                      >
                        <Link
                          offset={-80}
                          smooth={true}
                          to={"prop-obj-" + String(key)}
                        >
                          More info
                        </Link>
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="d-only">
            {portfolioData.map((prop, key) => {
              if (this.state.openProp === key) {
                return (
                  <PropView
                    data={prop}
                    close={this.toggleProp}
                    imageClass={key}
                    display={this.state.openProp === key}
                    pdf={prop.pdf}
                  />
                );
              }
            })}
          </div>
          <div className="m-only">
            {portfolioData.map((prop, key) => (
              <PropView
                passID={"prop-obj-" + String(key)}
                imageClass={key}
                data={prop}
                close={this.toggleProp}
                display={this.state.openProp === key}
                pdf={prop.pdf}
              />
            ))}
          </div>
          <Footer currentPage={"portfolio"} />
        </div>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default Portfolio;
